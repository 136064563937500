import React from "react";
const Test = () => {
  return (
    <>
      <div className="w-full max-w-[700px] mx-auto h-fit overflow-auto bg-white">
        <img
          src="https://api.inssain.co.kr/redirect?url=http://millinienimg.godohosting.com/c91113592_3123.gif"
          alt="test"
        />
      </div>
    </>
  );
};

export default Test;
