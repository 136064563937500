import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import copyright from "../../Asset/copyright.png";
import watermark from "../../Asset/watermark.png";

function AdSample() {
  const [agree, setAgree] = useState(false);
  const { name, type } = useParams();
  useEffect(() => {
    // body 요소 가져오기
    const body = document.body;

    // 기존 클래스 리스트 가져오기
    const currentClasses = body.className.split(" ");

    // "p-2" 클래스 제거 후 적용
    const updatedClasses = currentClasses.filter(cls => cls !== "p-2");
    body.className = updatedClasses.join(" ");

    return () => {
      // cleanup 함수 (필요하면 원래 상태로 복구 가능)
    };
  }, []);

  useEffect(() => {
    // body 요소 가져오기
    const body = document.body;

    if (!agree) {
      body.style.height = "100vh";
      body.style.overflow = "hidden";
    } else {
      window.scrollTo(0, 0);
      body.style.height = "";
      body.style.overflow = "";
    }

    return () => {
      // cleanup 함수 (필요하면 원래 상태로 복구 가능)
      body.style.height = "";
      body.style.overflow = "";
    };
  }, [agree]);
  return (
    <>
      {!agree && (
        <div className="fixed w-screen h-screen bg-black bg-opacity-50 z-[100]">
          <img
            src={copyright}
            className="max-w-[90%] w-auto lg:w-[800px] max-h-[100vh] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:cursor-pointer z-[101]"
            alt=""
            onClick={() => setAgree(true)}
          />
        </div>
      )}
      <div
        className="w-full max-w-[708px] mx-auto bg-white relative z-[50] p-1 h-fit"
        onContextMenu={e => {
          e.preventDefault();
          alert("무단도용 방지를 위해 우클릭 금지입니다");
        }}
      >
        <div
          className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-opacity-80"
          style={{
            backgroundImage: `url(${watermark})`,
            backgroundRepeat: "repeat",
          }}
        />
        <h2 className="bg-blue-600 text-white p-4 text-center text-2xl font-bold">
          코리아티엠 광고 샘플
        </h2>
        <img
          src={`https://api.inssain.co.kr/redirect?url=http://millinienimg.godohosting.com/${name}.${type}`}
          alt=""
          className="w-full"
        />
      </div>
    </>
  );
}

export default AdSample;
