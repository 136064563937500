import React from "react";

function TemplateModal(props) {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed z-50 outline-none focus:outline-none top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full xl:w-fit p-2 max-h-[95vh]">
        <div className="relative lg:w-auto my-6 mx-auto w-[90%] lg:min-w-[300px] max-w-full">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none max-h-full xl:p-6 p-2">
            <img
              src={props.template[0]}
              className="my-auto w-full lg:w-auto max-w-full h-auto lg:max-h-[80vh]"
              alt="쿠폰이미지"
            />
          </div>
        </div>
      </div>

      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        onClick={e => props.setTemplateModal(false)}
      ></div>
    </>
  );
}

export default TemplateModal;
